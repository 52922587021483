import React from "react";
// import ImgixFluidImage from "./ImgixFluidImage"

import { Box, Container, Title, Section, Subtitle } from "tailwind-react-ui";

// Image imports
import TriangleGY from "../images/patterns/patterns-triangles_greenyellow.svg";
import DotsGreen from "../images/patterns/patterns-dots_green.svg";

const BlogPageBanner = (props) => {
  const image = props.image;

  return (
    <>
      <Section
        className="flex items-center justify-center text-center relative flex-col bg-gray-200"
        p="0"
        bg="gray-200"
        data-component-type="sub-page-banner"
        style={{
          minHeight: "400px",
          backgroundImage: `url(${image.url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: image.focalPoint
            ? `${image.focalPoint[1] * 100}%`
            : "center",
          backgroundPositionX: image.focalPoint
            ? `${image.focalPoint[0] * 100}%`
            : "center",
          ar: 3,
        }}
      >
        <div className="bg-gradient-to-b from-black to-transparent absolute top-0 left-0 w-full h-full opacity-20"></div>
        <Container className={`py-10 lg:py-44 relative`}>
          <TriangleGY
            className="absolute left-0 top-full -mt-5"
            alt="Pattern"
          />
          <Box m="auto" className="lg:w-5/6">
            <Subtitle
              level={4}
              size={1}
              m="3"
              className="text-white text-sm lg:text-lg text-shadow-lg pb-1"
              font="heading"
            >
              {props.subtitle}
            </Subtitle>
            <Title
              level={1}
              text="white"
              font="heading"
              className={`text-4xl leading-none text-shadow-lg ${
                props.title.length < 20
                  ? "text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
                  : "md:text-5xl"
              }`}
            >
              {props.title}
            </Title>
          </Box>
        </Container>
        <DotsGreen
          className="hidden md:block absolute right-10 top-full -mt-20"
          alt="Pattern"
        />
      </Section>
    </>
  );
};

export default BlogPageBanner;
