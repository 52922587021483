import React from "react"
import { 
	graphql, 
	Link 
} from "gatsby"

// Layout
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Matrix from "../components/Matrix"
import { buildFluidImageData } from '@imgix/gatsby'

// Tailwind React UI Components
import { Container, FillButton, Flex, Section } from 'tailwind-react-ui'

// Custom Components
import BlogPageBanner from '../components/BlogPageBanner'

// Utilities
import { getFullDate } from "../utils/Dates"

// Icons/images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBooks } from '@fortawesome/pro-light-svg-icons'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// GraphQL
export const query = graphql`
  query BlogPostQuery($remoteId: ID!) {
    entry: craftBlogBlogEntry(remoteId: { eq: $remoteId  }) {
		id
		uri
		url
		title
		subtitle
		postDate
		remoteId
		typeHandle
		featureImage { ...UploadAssetFragment }
		metaTitle
		metaDescription
		metaKeywords
		metaImage { ...UploadAssetFragment }
		categories {
			... on Craft_categories_categories_Entry {
				title
				url
			}
		}
		bodyContent {
			... BodyContentFragment
		}
    }
	siteInfo: craftSiteInformationGlobalSet {
		...SiteInfoFragment
	}
  }
`

const truncateString = (string = '', maxLength = 54) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const OurBlog = ({ data: { entry, siteInfo } }) => {
	const [ siteImage ]  = siteInfo.siteImage
	const [ featureImage = siteImage ] = entry.featureImage
	const metaImage = featureImage || entry.metaImage || siteInfo.siteImage
	const metaDescription =  truncateString((entry.metaDescription || entry.summary || siteInfo.siteDescription).replace(/(<([^>]+)>)/gi, ""), 159)
	const metaTitle = truncateString(`${entry.metaTitle || entry.displayTitle || entry.title}`, 53)
	const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300
		}
    )

	return (
		<Layout location={entry.url} title={metaTitle}>
			<Seo
				title       = {metaTitle}
				description = {metaDescription}
				keywords    = {metaKeywords}
				image       = {metaImageFormatted.src}
				url         = {entry.url}
				pathname	= {`/${entry.uri}`}
			/>
			<h1 style={{fontSize: 0, color: 'transparent'}} className="sr-only">{metaTitle}</h1>

			{featureImage && (
				<Section p="0" className="relative">
					<BlogPageBanner
						image    = {featureImage}
						title    = {entry.title}
						subtitle = {`Our blog | ` + getFullDate(entry.postDate)}
					/>
				</Section>
			)}

			<Section p="0">
				<Container className="xl:w-3/5 pt-16 pb-6">
					{entry.id.includes(79189) ?<Flex items="center" justify="start" wrap className="flex-wrap items-center justify-start mb-8">
                        <Link className="px-4 py-2 bg-green text-sm ml-0 rounded-full text-white border-green focus:bg-primary hover:bg-primary focus:text-white hover:text-white hover:border-primary focus:border-primary leading-loose font-display transition duration-200 ease-in-out" to="https://www.worldtravelawards.com/vote" aria-label="Vote for us on the World Travel Awards website!">Vote now!</Link>
                        <Link to="https://www.worldtravelawards.com/vote" aria-label="Vote for us on the World Travel Awards website!" className="ml-0 sm:ml-6 mr-auto"><img alt="World Travel Awards logo" src="https://tmac2021.imgix.net/uploads/logos/World-Travel-Awards-logo.png" width="125" /></Link>
                    </Flex>: ''}

					<Flex items="baseline" justify="start" wrap>
						<time className="text-sm block mb-3" dateTime={getFullDate(entry.postDate)}>{getFullDate(entry.postDate)}</time>
						
						{entry.categories.map((category, i) => (
							<FillButton key={i} font="display" data-type="button" bg="gray-light" bg-hocus="primary" border-hocus="primary" text="gray-500" is="a" href={category.uri} text-hocus="white" leading="none" className="transition duration-300 ease-in-out border text-xs px-2 py-0 rounded-full whitespace-nowrap ml-2">
								<small>{category.title}</small>
							</FillButton>
						))}

						<Link className="px-4 py-2 bg-primary text-sm ml-auto rounded-full text-white border-primary focus:bg-yellow hover:bg-yellow focus:text-primary hover:text-primary leading-loose font-display transition duration-200 ease-in-out" to="/our-blog" aria-label="Return to blog"><FontAwesomeIcon color="inherit" size="lg" icon={faBooks} />&nbsp;&nbsp;Return to blog</Link>
					</Flex>
				</Container>
			</Section>

			{entry.bodyContent.length ? <Section p="0" className="secondary-page blog">
				<Container className="xl:w-3/5">
					<Matrix blocks={entry.bodyContent} />
				</Container>
			</Section>: '' }

			<Section p="0">
				<Container className="xl:w-3/5 pt-10 pb-20 justify-end flex">
					<Link className="px-4 py-2 bg-primary text-sm ml-auto rounded-full text-white border-primary focus:bg-yellow hover:bg-yellow focus:text-primary hover:text-primary leading-loose font-display transition duration-200 ease-in-out" to="/our-blog" aria-label="Return to blog"><FontAwesomeIcon color="inherit" size="lg" icon={faBooks} />&nbsp;&nbsp;Return to blog</Link>
				</Container>
			</Section>
			
		</Layout>
	)
}

export default OurBlog




// import React from "react"
// import { graphql } from "gatsby"

// import Layout from "../components/Layout"
// import Seo from "../components/Seo"

// class BlogPostTemplate extends React.Component {
//   render() {
//     const post = this.props.data.markdownRemark
//     const siteTitle = this.props.data.site.siteMetadata.title
//     const image = post.frontmatter.image
//       ? post.frontmatter.image.childImageSharp.resize
//       : null

//     return (
//       <Layout location={this.props.location} title={siteTitle}>
//         <Seo
//           title={post.frontmatter.title}
//           description={post.frontmatter.description || post.summary}
//           image={image}
//           pathname={this.props.location.pathname}
//         />
//         <h1>{post.frontmatter.title}</h1>
//         <div dangerouslySetInnerHTML={{ __html: post.html }} />
//       </Layout>
//     )
//   }
// }

// export default BlogPostTemplate

// // export const pageQuery = graphql`
// //   query BlogPostBySlug($slug: String!) {
// //     site {
// //       siteMetadata {
// //         title
// //         author
// //       }
// //     }
// //     markdownRemark(fields: { slug: { eq: $slug } }) {
// //       id
// //       excerpt(pruneLength: 160)
// //       html
// //       frontmatter {
// //         title
// //         description
// //         image: featured {
// //           childImageSharp {
// //             resize(width: 1200) {
// //               src
// //               height
// //               width
// //             }
// //           }
// //         }
// //       }
// //     }
// //   }
// // `